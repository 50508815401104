import {
  faChevronLeft,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

type TProps = {
  currentPage: number;
  setPage: (page: number) => void;
  handlePageChange: (prev?: boolean) => void;
  total: number;
  className: string;
};

const Paginator = ({
  currentPage = 1,
  setPage,
  handlePageChange,
  total = 1,
  className = '',
}: TProps) => {
  const [visibleNumbers, setVisibleNumbers] = useState<number[]>([]);
  useEffect(() => {
    const tempArr: number[] = [];
    for (let i = 0; i < 3; i++) {
      let number = currentPage + (i - 1);
      while (number < 1 || tempArr.includes(number)) number += 1;
      while (number > total || tempArr.includes(number)) number -= 1;
      tempArr.push(number);
    }
    setVisibleNumbers(tempArr.sort((a, b) => a - b));
  }, [currentPage, total]);

  return (
    <div
      className={`flex items-center gap-2 @lg/table:gap-4 select-none ${className}`}>
      <button
        disabled={currentPage < 2}
        onClick={() => setPage(1)}
        className='flex items-center gap-2 disabled:text-app-400 p-3'>
        <FontAwesomeIcon icon={faChevronsLeft} />
      </button>
      <button
        disabled={currentPage < 2}
        onClick={() => handlePageChange(true)}
        className='flex items-center gap-2 disabled:text-app-400 p-3'>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <div className='flex gap-2 items-center'>
        {visibleNumbers.map((number) => {
          return (
            number >= 1 &&
            number <= total && (
              <button
                key={number}
                disabled={currentPage === number}
                className={`${
                  currentPage === number
                    ? 'bg-app-500 text-alpha @lg/table:flex justify-center items-center'
                    : ''
                } w-8 h-8 rounded-lg`}
                onClick={() => setPage(number)}>
                {number}
              </button>
            )
          );
        })}
      </div>
      <button
        disabled={currentPage >= total}
        onClick={() => handlePageChange()}
        className='flex items-center gap-2 disabled:text-app-400 p-3'>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
      <button
        disabled={currentPage >= total}
        onClick={() => setPage(total)}
        className='flex items-center gap-2 disabled:text-app-400 p-3'>
        <FontAwesomeIcon icon={faChevronsRight} />
      </button>
    </div>
  );
};

export default Paginator;

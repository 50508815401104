export const widgetContentMapping = {
  DetailSide: 'deviceSpecsContent',
  UserDetails: 'deviceSpecsContent',
  Address: 'addressContent',
  Confirmation: 'confirmationContent',
  Table: 'tableContent',
  ImportModal: 'importContent',
  ChangeInstitution: 'editInstitutionContent',
  ReturnDeviceLoanModal: 'returnDeviceLoanContent',
  DeviceLoanCard: 'deviceLoansContent',
  CreateBulkIncidents: 'createFieldServiceCaseContent',
  SearchableSelect: 'searchableSelectContent',
};

import { ColumnDef, ColumnSort } from '@tanstack/react-table';
import { ReactNode } from 'react';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { EFilterType } from '../enum/filterType';

export type TFilerInputs = 'select' | 'checkbox';

type TDefaultFilter = {
  type: TFilerInputs;
  key: string;
  accessorKey: string;
  filterType: EFilterType;
  aditionalFilterTemplateString?: string;
  label?: string;
  defaultValue?: string;
};

type TSelectFilter = TDefaultFilter & {
  options: { label: string; value: string }[];
};

export const isTSelectFilter = (
  filter: TTableFilters,
): filter is TSelectFilter => {
  return (filter as TSelectFilter).type === 'select';
};

type TBooleanFilter = TDefaultFilter & {
  value?: string;
};

export const isTBooleanFilter = (
  filter: TTableFilters,
): filter is TBooleanFilter => {
  return (filter as TBooleanFilter).type === 'checkbox';
};

type TPageItemsConfig = {
  options?: number[];
  defaultAmount: number;
};

type TFetchOptions = {
  axiosInstance: AxiosInstance;
  axiosConfig?: Omit<AxiosRequestConfig, 'params'>;
  refetchKey?: string;
  subUrl?: string;
  $expand?: string;
};

export type TTableFilters = TSelectFilter | TBooleanFilter;

export type TTableProps<T> = {
  columns: ColumnDef<T, unknown>[];
  tableKey: string;
  fetchOptions: TFetchOptions;
  contentGuid: string;
  translation?: Record<string, string>;
  initialSortBy?: ColumnSort;
  tableConfig?: {
    tableActions?: ReactNode;
  };
  isLoadingContent?: boolean;
  tableDataCy?: string;
  defaultFilter?: string;
  filters?: TTableFilters[];
  $search?: string;
  dowloadable?: boolean;
  pageItemsConfig?: TPageItemsConfig;
  searchable?: boolean;
};

import {
  faEllipsisVertical,
  faEye,
  faEyeSlash,
  faFilters,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Reorder } from 'framer-motion';
import { useClickOutside } from '../../../hooks/useClickOutside.ts';
import { Table, VisibilityState } from '@tanstack/react-table';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import Filters from './filters.tsx';
import { TTableFilters } from '../../../types/table/tableTypes.ts';
import { FieldValues } from 'react-hook-form';
import { getFilterCount } from '../../../helpers/filters.ts';
import { TranslationContext } from '../../../context/translationContext.ts';

type TProps<T> = {
  table: Table<T>;
  columnVisibility: VisibilityState;
  tableKey: string;
  children: ReactNode;
  setFilter: Dispatch<SetStateAction<string>>;
  defaultFilters: FieldValues;
  filters?: TTableFilters[];
  currentFilterString?: string;
};

const Head = <T,>({
  table,
  columnVisibility,
  tableKey,
  children,
  filters,
  defaultFilters,
  currentFilterString,
  setFilter,
}: TProps<T>) => {
  const translation = useContext(TranslationContext);
  const [showOptions, setShowOption, optionRef] =
    useClickOutside<HTMLDivElement>({
      ignoreClass: 'ignoreOptionList',
    });
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const allColumns = table.getAllLeafColumns();
  const configurableColumns = allColumns.filter((column) =>
    column.getCanHide(),
  );
  const advancedFilterColumns = allColumns.filter((column) => {
    return column.columnDef?.enableGlobalFilter || false;
  });
  const filterCount = getFilterCount(currentFilterString || '');
  const hasFilters =
    (filters && filters.length > 0) || advancedFilterColumns.length > 0;

  return (
    <div>
      <div className='flex justify-between gap-4 p-4'>
        <div className='flex items-center gap-2'>
          <div className='relative h-full'>
            <button
              title='Opties'
              onClick={() => setShowOption(!showOptions)}
              className='ignoreOptionList h-full bg-app-200 px-4 py-2 rounded-md border border-app-400 flex items-center gap-4 hover:bg-app-400 hover:border-app-500 transition-colors'>
              <span className='hidden @lg/main:inline'>
                {translation.options}
              </span>
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                className='text-app-800'
              />
            </button>
            {showOptions && (
              <div
                ref={optionRef}
                className='flex absolute top-full left-0 bg-alpha shadow-md shadow-app-800/20 z-10 rounded-lg p-4 w-max'>
                <ul className='border-b border-app-400 last:border-none'>
                  <li>
                    <form action='#' className='flex flex-col'>
                      <legend className='font-medium mb-2'>
                        {translation.columns}
                      </legend>
                      <fieldset className='flex flex-col gap-4'>
                        <Reorder.Group
                          axis='y'
                          values={configurableColumns.map((col) => col.id)}
                          onReorder={(newOrder) => {
                            localStorage.setItem(
                              `${tableKey}Order`,
                              newOrder.toString(),
                            );
                            table.setColumnOrder(newOrder);
                          }}
                          className='relative'>
                          {configurableColumns.map((column) => (
                            <Reorder.Item
                              key={column.id}
                              value={column.id}
                              className='py-2 px-4 border-b border-app-400 last:border-none flex items-center gap-8 justify-between cursor-ns-resize'>
                              <span>{column.columnDef.header?.toString()}</span>
                              <label>
                                <input
                                  className='peer/visibility appearance-none'
                                  type='checkbox'
                                  checked={column.getIsVisible()}
                                  onChange={(e) => {
                                    column.getToggleVisibilityHandler()(e);
                                    /*
                                    * Clone state to constant before saving it to localstorage.
                                    ! Without this, the localstorage will be one step behind the actual state.
                                  */
                                    const clone = { ...columnVisibility };
                                    clone[column.id] = !column.getIsVisible();
                                    localStorage.setItem(
                                      `${tableKey}Vis`,
                                      JSON.stringify(clone),
                                    );
                                  }}
                                />
                                <FontAwesomeIcon
                                  icon={faEye}
                                  className='hidden peer-checked/visibility:inline w-5 text-app-500'
                                />
                                <FontAwesomeIcon
                                  icon={faEyeSlash}
                                  className='inline peer-checked/visibility:hidden w-5 text-app-400'
                                />
                              </label>
                            </Reorder.Item>
                          ))}
                        </Reorder.Group>
                      </fieldset>
                    </form>
                  </li>
                </ul>
              </div>
            )}
          </div>
          {hasFilters && (
            <button
              title='Filters'
              onClick={() => setShowFilters((prevState) => !prevState)}
              className={`group h-full relative bg-app-200 px-4 py-2 rounded-md border border-app-400 hover:bg-app-400 hover:border-app-500 transition-colors hover:text-app-800 ${
                showFilters ? 'bg-app-600 border-app-700 text-alpha' : ''
              }`}>
              <div className='flex items-center gap-4'>
                <span className='hidden @lg/main:inline'>
                  {translation.filters}
                </span>
                <FontAwesomeIcon
                  icon={faFilters}
                  className={`group-hover:text-app-800 ${
                    showFilters ? 'text-alpha' : 'text-app-800'
                  }`}
                />
                {filterCount > 0 && (
                  <div className='absolute -top-2 -right-2 rounded-full w-4 h-4 bg-signpost ring-app-200 ring-2 text-app-800 flex items-center justify-center text-center' />
                )}
              </div>
            </button>
          )}
        </div>
        <div className='flex items-center'>{children}</div>
      </div>
      {showFilters && filters && (
        <Filters
          filters={filters}
          setFilter={setFilter}
          defaultFilters={defaultFilters}
          columns={advancedFilterColumns}
        />
      )}
    </div>
  );
};

export default Head;

import { createContext } from 'react';
import { TComponent } from '../views/private';

const defaultWidgetContext: TComponent = {
  columnSpan: 0,
  schemaName: 'default',
  content: [],
  linkedContent: {},
};

export const WidgetContext = createContext<TComponent>(defaultWidgetContext);

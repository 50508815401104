import AdvancedFilterLine from '@/components/common/tableComponent/advancedFilterLine';
import { TranslationContext } from '@/context/translationContext';
import { mapAdvancedFilters } from '@/helpers/filters';
import { useApplyFilters } from '@/hooks/useApplyFilters';
import { faArrowRight, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Column } from '@tanstack/react-table';
import { Dispatch, SetStateAction, useContext } from 'react';
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';

type TAdvancedFilter = {
  accessorKey?: string;
  type?: string;
  value?: string;
};

export type TAdvancedFilterForm = {
  filters: TAdvancedFilter[];
};

type TAdvancedFilterProps<T> = {
  columns: Column<T, unknown>[];
  setFilter: Dispatch<SetStateAction<string>>;
  defaultFilters?: FieldValues;
};

const AdvancedFilters = <T,>({
  columns,
  setFilter,
  defaultFilters,
}: TAdvancedFilterProps<T>) => {
  const translation = useContext(TranslationContext);
  const applyFilters = useApplyFilters(setFilter);

  const { control, register, handleSubmit, watch } =
    useForm<TAdvancedFilterForm>({
      defaultValues: {
        filters:
          defaultFilters?.filters && defaultFilters?.filters.length > 0
            ? defaultFilters.filters
            : [{ accessorKey: undefined, type: undefined, value: undefined }],
      },
    });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'filters',
  });

  const submitHandler = (data: TAdvancedFilterForm) => {
    const transformedData = mapAdvancedFilters(data);
    applyFilters(transformedData);
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      {fields.map((field, index) => (
        <AdvancedFilterLine
          key={field.id}
          field={field}
          index={index}
          watch={watch}
          register={register}
          remove={remove}
          columns={columns}
        />
      ))}
      <div className='flex justify-end mx-4 gap-2 mb-4'>
        <button
          type='button'
          className='bg-app-200 px-4 py-2 rounded-md flex items-center gap-4 hover:bg-app-400 transition-colors'
          onClick={() => {
            append({
              accessorKey: undefined,
              type: undefined,
              value: undefined,
            });
          }}>
          <span>{translation.add}</span>
          <FontAwesomeIcon icon={faPlus} />
        </button>
        <button
          className='text-app-100 bg-app-700 px-4 py-2 rounded-md flex items-center gap-4 hover:bg-app-600 transition-colors'
          type='submit'>
          <span>{translation.applyFilters}</span>
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </form>
  );
};

export default AdvancedFilters;

import { Dispatch, Reducer, useEffect, useMemo, useReducer } from 'react';
import {
  pageReducer,
  TPageAction,
  TPageState,
} from '../helpers/paginationReducer';
import { EPageActionType } from '../types/enum/pageActionType.ts';
import { useSearchParams } from 'react-router-dom';

type TPaginationReturn = {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  $top: number;
  $skip: number;
  pageDispatch: Dispatch<TPageAction>;
};

export const usePagination = (
  itemsOnPage: number,
  defaultPage = 1,
  total = 0,
): TPaginationReturn => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [{ totalItems, totalPages, currentPage, $skip, $top }, pageDispatch] =
    useReducer<Reducer<TPageState, TPageAction>>(pageReducer, {
      $top: itemsOnPage,
      $skip: (defaultPage - 1) * itemsOnPage,
      totalItems: total,
      totalPages: 0,
      currentPage: defaultPage,
    });

  useMemo(() => {
    if (currentPage > totalPages && totalPages >= 1) {
      pageDispatch({
        payload: { page: totalPages },
        type: EPageActionType.SETPAGE,
      });
    }
  }, [totalPages, currentPage]);

  useEffect(() => {
    const params: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    setSearchParams({ ...params, page: `${currentPage}` }, { replace: true });
  }, [totalPages, currentPage, searchParams, setSearchParams]);

  return { currentPage, totalPages, totalItems, pageDispatch, $top, $skip };
};

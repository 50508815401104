import { getQueryParamsObj } from '@/helpers/queryParams';
import { ColumnSort } from '@tanstack/react-table';
import { Dispatch, SetStateAction } from 'react';
import { NavigateOptions, URLSearchParamsInit } from 'react-router-dom';

export const generateOrderByValue = (sortBy?: ColumnSort): string =>
  sortBy ? `${sortBy.id}${sortBy.desc ? ' desc' : ''}` : '';

export const setSortingState = (
  searchParams: URLSearchParams,
  setSearchParams: (
    nextInit?:
      | URLSearchParamsInit
      | ((prev: URLSearchParams) => URLSearchParamsInit),
    navigateOpts?: NavigateOptions,
  ) => void,
  state: ColumnSort | undefined,
  stateCallbackFn?: Dispatch<SetStateAction<ColumnSort | undefined>>,
  id?: string,
) => {
  if (!stateCallbackFn || !id) return;
  const newState = generateState(id, state);
  if (!newState) {
    searchParams.delete('sortBy');
  }
  setSearchParams(
    {
      ...getQueryParamsObj(searchParams),
      ...(newState && { sortBy: JSON.stringify(newState) }),
    },
    { replace: true },
  );
  stateCallbackFn(newState);
};

const generateColumnSort = (id: string): ColumnSort => ({ desc: true, id: id });

const generateState = (
  id: string,
  prevState?: ColumnSort,
): ColumnSort | undefined => {
  if (!prevState || prevState.id !== id) return generateColumnSort(id);
  if (!prevState.desc) return undefined;
  return { desc: false, id: prevState.id };
};

export const getSortBy = (
  sortByState?: ColumnSort,
  id?: string,
): 'none' | 'desc' | 'asc' => {
  if (!sortByState || !id || sortByState.id !== id) return 'none';
  return sortByState.desc ? 'desc' : 'asc';
};

type PatternReplacementPair = {
  pattern: string;
  replacement: string;
};

export const getFirstLetter = (value: string): string => value.slice(0, 1);

export const kebabize = (str: string) =>
  str.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    ($, ofs) => (ofs ? '-' : '') + $.toLowerCase(),
  );

export const replacePatterns = (
  input: string,
  replacements: PatternReplacementPair[],
): string => {
  let result = input;
  replacements.forEach(({ pattern, replacement }) => {
    result = result.replace(pattern, replacement);
  });
  return result;
};

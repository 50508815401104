export enum EFilterType {
  Equals = 'eq',
  NotEquals = 'neq',
  GreaterThan = 'gt',
  GreaterThanOrEquals = 'gte',
  LessThan = 'lt',
  LessThanOrEquals = 'lte',
  Contains = 'contains',
  NotContains = 'ncontains',
  In = 'in',
  NotIn = 'nin',
  IsNull = 'null',
  IsNotNull = 'nnull',
}

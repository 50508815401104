import { TAdvancedFilterForm } from '@/components/common/tableComponent/advancedFilters';
import { TranslationContext } from '@/context/translationContext';
import { EFilterType } from '@/types/enum/filterType';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '@signpost-hardware/react-components';
import { Column } from '@tanstack/react-table';
import { useContext } from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';

type TAdvancedFilterLine<T> = {
  field: FieldArrayWithId<TAdvancedFilterForm, 'filters', 'id'>;
  index: number;
  register: UseFormRegister<TAdvancedFilterForm>;
  columns: Column<T, unknown>[];
  remove: UseFieldArrayRemove;
  watch: UseFormWatch<TAdvancedFilterForm>;
};

const AdvancedFilterLine = <T,>({
  field,
  index,
  register,
  columns,
  remove,
  watch,
}: TAdvancedFilterLine<T>) => {
  const translation = useContext(TranslationContext);
  const watchAccessorKey = watch('filters')[index].accessorKey;
  const inputType =
    columns.filter(({ id }) => id === watchAccessorKey)[0]?.columnDef?.meta
      ?.type || 'text';

  return (
    <div
      key={field.id}
      className='grid grid-cols-[repeat(3,1fr)_auto] mx-4 gap-2'>
      <label className='flex flex-col'>
        <span className='mb-2'>{translation.column}:</span>
        <select
          className='px-3 py-2 w-full text-ellipsis border-alpha-700 border hover:border-signpost focus:border-signpost rounded-[3px] outline-none'
          {...register(`filters.${index}.accessorKey`, {
            required: translation.required,
          })}>
          {columns.map((column) => (
            <option
              key={column.id}
              value={column.id}>{`${column.columnDef.header}`}</option>
          ))}
        </select>
      </label>
      <label className='flex flex-col'>
        <span className='mb-2'>{translation.type}:</span>
        <select
          className='px-3 py-2 w-full text-ellipsis border-alpha-700 border hover:border-signpost focus:border-signpost rounded-[3px] outline-none'
          {...register(`filters.${index}.type`, {
            required: translation.required,
          })}>
          {(Object.keys(EFilterType) as (keyof typeof EFilterType)[]).map(
            (key) => (
              <option value={EFilterType[key]} key={key}>
                {translation[EFilterType[key]]}
              </option>
            ),
          )}
        </select>
      </label>
      <Input
        label={translation.value}
        type={inputType}
        key={field.id}
        {...register(`filters.${index}.value`, {
          required: translation.required,
        })}
      />
      <button
        title={translation.delete}
        type='button'
        className='w-8 h-8 flex justify-center items-center self-center mt-4 bg-alpha-200 rounded-md hover:bg-info-error/40 transition-colors'
        onClick={() => remove(index)}>
        <FontAwesomeIcon icon={faTrashAlt} />
      </button>
    </div>
  );
};

export default AdvancedFilterLine;

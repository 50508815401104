export const defaultTableContent = {
  results: 'Results',
  resultsPerPage: 'Results per page',
  noResults: 'No results',
  options: 'Options',
  columns: 'Columns',
  filters: 'Filters',
  reset: 'Reset',
  advancedFilters: 'Advanced Filters',
  add: 'Add',
  applyFilters: 'Apply filters',
  column: 'Column',
  type: 'Type',
  value: 'Value',
  required: 'Required',
  delete: 'Delete',
  eq: 'Equals',
  neq: 'Not equals',
  gt: 'Greater than',
  gte: 'Greater than or equals',
  lt: 'Less than',
  lte: 'Less than or equals',
  contains: 'Contains',
  ncontains: 'Not contains',
  in: 'In',
  nin: 'Not in',
  null: 'Null',
  nnull: 'Not Null',
};

import SkeletonWidget from '@/components/widgets/helpers/skeletonWidget.tsx';
import { WidgetContext } from '@/context/widgetContext.ts';
import { widgetContentMapping } from '@/helpers/componentMappings/widgetContentMapping.ts';
import { kebabize } from '@/helpers/stringHelpers.ts';
import { defaultTableContent } from '@/helpers/table/defaultTableContent.ts';
import { useContent } from '@/hooks/useContent.ts';
import {
  faFileExcel,
  faMagnifyingGlass,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ColumnOrderState,
  ColumnSort,
  flexRender,
  getCoreRowModel,
  Header,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { useContext, useRef, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { Id, toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';
import { TranslationContext } from '../../../context/translationContext.ts';
import { fileDownload } from '../../../helpers/fileDownload.ts';
import {
  getDefaultFilterString,
  getDefaultValuesFromQueryParams,
} from '../../../helpers/filters.ts';
import { getQueryParamsObj } from '../../../helpers/queryParams.ts';
import {
  generateOrderByValue,
  getSortBy,
  setSortingState,
  sortIcon,
} from '../../../helpers/table/index.ts';
import { usePagination } from '../../../hooks/usePagination.ts';
import { EPageActionType } from '../../../types/enum/pageActionType.ts';
import { TTableProps } from '../../../types/table/tableTypes.ts';
import Head from './head.tsx';
import Paginator from './paginator.tsx';

const Table = <T,>({
  columns,
  tableKey,
  contentGuid,
  initialSortBy,
  defaultFilter,
  filters,
  $search,
  tableDataCy,
  tableConfig: { tableActions } = {},
  fetchOptions: {
    axiosInstance,
    subUrl = '/',
    refetchKey,
    axiosConfig,
    $expand,
  },
  translation = defaultTableContent,
  isLoadingContent = false,
  dowloadable = false,
  searchable = false,
  pageItemsConfig = {
    defaultAmount: 15,
    options: [15, 30, 50, 100],
  },
}: TTableProps<T>) => {
  const schemaName = widgetContentMapping.Table;
  const { linkedContent } = useContext(WidgetContext);
  const { data: loadedTranslation } = useContent(
    kebabize(schemaName),
    linkedContent[schemaName],
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultPageSize = Math.abs(
    parseInt(
      searchParams.get('pageSize') || `${pageItemsConfig.defaultAmount}`,
    ),
  );
  const { $top, $skip, totalItems, totalPages, pageDispatch, currentPage } =
    usePagination(
      pageItemsConfig?.options?.reduce((prev, curr) =>
        Math.abs(curr - defaultPageSize) < Math.abs(prev - defaultPageSize)
          ? curr
          : prev,
      ) || pageItemsConfig.defaultAmount,
      Math.abs(parseInt(searchParams.get('page') || '1')),
    );
  const params: FieldValues = getQueryParamsObj(searchParams);
  const defaultFilters = getDefaultValuesFromQueryParams(params);
  const defaultFilterString = getDefaultFilterString(defaultFilters);
  const defaultHidden = Object.fromEntries(
    columns
      .filter((column) => column.meta?.defaultHidden)
      .map((column) => [[`${column.id}`], false]),
  );
  const [$filter, set$Filter] = useState<string>(
    defaultFilterString || defaultFilter || '',
  );
  const [search, setSearch] = useState<string | undefined>(
    params.$search || $search,
  );
  const [debouncedSearch] = useDebounce(search, 500);
  const toastId = useRef<string | null | Id>(null);
  const [tableData, setTableData] = useState<T[]>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    JSON.parse(
      localStorage.getItem(`${tableKey.toLowerCase()}Vis`) ??
        JSON.stringify(defaultHidden),
    ),
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    localStorage.getItem(`${tableKey.toLowerCase()}Order`)?.split(',') ?? [],
  );
  const [sortBy, setSortBy] = useState<ColumnSort | undefined>(
    JSON.parse(`${searchParams.get('sortBy')}`) || initialSortBy,
  );
  const table = useReactTable({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    pageCount: totalPages,
    state: {
      columnVisibility,
      columnOrder,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
  });

  const isSortable = (header: Header<T, unknown>) =>
    header.column.columnDef.enableSorting;

  const { error, isFetching } = useQuery<T[]>({
    queryKey: [
      refetchKey,
      tableKey,
      $top,
      $skip,
      $filter,
      $expand,
      generateOrderByValue(sortBy),
      subUrl,
      $search,
      axiosConfig,
      debouncedSearch,
    ],
    queryFn: async () => {
      const res = await axiosInstance.get(subUrl, {
        params: {
          $top,
          $skip,
          $orderby: generateOrderByValue(sortBy),
          $expand,
          $filter,
          $search: debouncedSearch,
        },
        ...axiosConfig,
      });
      pageDispatch({
        payload: { total: res.data.total, itemsOnPage: $top },
        type: EPageActionType.INIT,
      });
      setTableData(res.data.items);
      return res.data;
    },
  });

  const downloadCsvMutation = useMutation<T[]>({
    mutationFn: async () => {
      const res = await axiosInstance.get(subUrl, {
        params: {
          $top,
          $skip,
          $orderby: generateOrderByValue(sortBy),
          $expand,
          $filter,
          $search: debouncedSearch,
        },
        ...axiosConfig,
        headers: {
          Accept: 'text/csv',
        },
      });
      fileDownload(res.data, `${tableKey}.csv`);
      return res.data;
    },
    onMutate: () => {
      toastId.current = toast.info('Export downloading', {
        autoClose: false,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        closeButton: false,
        closeOnClick: false,
      });
    },
    onError: () => toast.error('Something went wrong'),
    onSettled: () => {
      if (toastId.current) toast.dismiss(toastId.current);
    },
  });

  if (!tableData || isLoadingContent) return <SkeletonWidget />;
  if (error) return <div>error</div>;
  return (
    <TranslationContext.Provider
      value={{ ...translation, ...loadedTranslation }}>
      {searchable && (
        <label className='relative flex items-center mb-2 bg-alpha rounded-full pl-4 border border-app-400 cursor-pointer max-w-80'>
          <div className='flex items-center pr-4 border-r border-app-400 h-3/5'>
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className='text-app-500'
              size='sm'
            />
          </div>
          <input
            type='search'
            value={search}
            className='w-full px-4 py-2 focus:outline-none placeholder:text-app-500 rounded-e-full'
            onChange={(e) => {
              setSearch(e.currentTarget.value);
              searchParams.delete('$search');
              if (e.currentTarget.value !== '') {
                setSearchParams(
                  {
                    ...searchParams,
                    $search: e.currentTarget.value,
                  },
                  { replace: true },
                );
              } else {
                setSearchParams(
                  {
                    ...searchParams,
                  },
                  { replace: true },
                );
              }
            }}
          />
        </label>
      )}
      <div
        className='relative border border-app-400 rounded-xl bg-alpha grid @container/table'
        data-cy={contentGuid}>
        {isFetching && (
          <div className='backdrop-blur-sm absolute w-full h-full inset-0 z-20 rounded-xl'></div>
        )}
        <Head
          table={table}
          columnVisibility={columnVisibility}
          filters={filters}
          currentFilterString={$filter}
          setFilter={set$Filter}
          defaultFilters={defaultFilters}
          tableKey={tableKey}>
          {tableActions}
          {dowloadable && (
            <button
              disabled={downloadCsvMutation.isPending}
              className='bg-app-200 h-full hover:bg-app-400 px-4 py-2 rounded-md disabled:opacity-80 ml-2 transition-colors'
              onClick={() => {
                downloadCsvMutation.mutate();
              }}>
              <FontAwesomeIcon icon={faFileExcel} />
            </button>
          )}
        </Head>
        <div className='overflow-y-auto'>
          <table className='w-full' data-cy={tableDataCy}>
            <thead className='text-left border-t border-b border-app-400 text-sm'>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const sortable = isSortable(header);
                    return (
                      <th
                        key={header.id}
                        onClick={() => {
                          if (sortable) {
                            setSortingState(
                              searchParams,
                              setSearchParams,
                              sortBy,
                              setSortBy,
                              header.id,
                            );
                          }
                        }}
                        className={`${sortable ? 'cursor-pointer' : ''} relative text-app-500 font-normal after:content-[""] after:w-[1px] after:bg-app-400 after:h-3/6 after:absolute after:top-1/2 after:-translate-y-1/2 after:right-0 after:text-app-400 m-2 last:after:w-0 p-4`}>
                        <div className='flex justify-between items-center gap-2'>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                          {sortable && (
                            <FontAwesomeIcon
                              className={`${sortIcon[getSortBy(sortBy, header.id)].color}`}
                              icon={sortIcon[getSortBy(sortBy, header.id)].icon}
                              swapOpacity={
                                sortIcon[getSortBy(sortBy, header.id)]
                                  .swapOpacity
                              }
                            />
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows &&
              table.getRowModel().rows.length > 0 ? (
                table.getRowModel().rows.map((row) => (
                  <tr key={row.id} className='even:bg-app-200'>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className='p-4'>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    className='p-8 text-center w-full'
                    colSpan={table.getHeaderGroups()[0].headers.length}>
                    {translation?.noResults}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className='border-t border-app-400'>
          <div className='flex flex-col gap-2 p-4 text-sm items-center'>
            <Paginator
              className='justify-self-center'
              currentPage={currentPage}
              setPage={(page) => {
                pageDispatch({
                  type: EPageActionType.SETPAGE,
                  payload: { page },
                });
              }}
              handlePageChange={(prev = false) => {
                if (prev) {
                  pageDispatch({ type: EPageActionType.PREVIOUS });
                  return;
                }
                pageDispatch({ type: EPageActionType.NEXT });
              }}
              total={totalPages}
            />
            {totalItems && totalItems > 0 ? (
              <span className='text-app-500 text-center'>
                {totalItems} {translation?.results}
              </span>
            ) : null}
            <label className='text-sm text-app-500 flex items-center gap-2'>
              <span>{translation?.resultsPerPage}:</span>
              <select
                defaultValue={$top}
                name='itemsOnPage'
                className='border border-app-400 rounded-md'
                onChange={(e) => {
                  pageDispatch({
                    type: EPageActionType.SETPAGESIZE,
                    payload: { itemsOnPage: parseInt(e.target.value) },
                  });
                  setSearchParams(
                    {
                      ...getQueryParamsObj(searchParams),
                      pageSize: e.target.value,
                    },
                    { replace: true },
                  );
                }}>
                {pageItemsConfig.options?.map((option) => (
                  <option value={option} key={option}>
                    {option}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>
      </div>
    </TranslationContext.Provider>
  );
};
export default Table;
